module.exports = {
  titleShort: `JochenOnTour`,
  title: `Jochen On Tour Blog`,
  url: `https://jochenontour.blog`,
  description: `Roadtrips through the world`,
  twitter: `jochenhiller`,
  instagram: `jochen.hiller`,
  gaId: `G-ECL65CPTYE`,
  email: `jo.hiller@gmail.com`,
  github: `JochenHiller/jochenontour`,
  facebook: `jo.hiller`,
  instagram_id: `7484852520`,
  disqus: `jochenontour`,
}
